import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from "@amzn/katal-monitoring-aws-driver";
import KatalMetricsPublisher from "@amzn/katal-metrics/lib/KatalMetricsPublisher";
import { MetricEmitter } from "@amzn/document-analysis-widget-assets";
import KatalMetricsDriver from "@amzn/katal-metrics/lib/driver/KatalMetricsDriver";
import KatalMetricsContext from "@amzn/katal-metrics/lib/KatalMetricsContext";

class MetricsImpl {
    private static publisher: KatalMetricsPublisher;

    public static metricsErrorHandler(err: Error): void {
        console.error(err);
    }

    public static getDriver(): KatalMetricsDriver {
        const monitoringConfig: KatalMonitoringDriverOptions = {
            url: "https://trogoqpc5d.execute-api.us-west-2.amazonaws.com/prod/v1/monitoring"
        };
        return new KatalMonitoringDriver(monitoringConfig);
    }

    public static getPublisher(): KatalMetricsPublisher {
        const metricsDriver = MetricsImpl.getDriver();
        const metricsContext = new KatalMetricsContext.Builder()
            .withSite("ParagonDvrDocumentTranslationWidgetWebsite")
            .withServiceName("ParagonDvrDocumentTranslationWidget")
            .build();

        /**
         * A singleton of the KatalMetrics.Publisher that everyone can use to record metrics as they see fit.
         */
        MetricsImpl.publisher = new KatalMetricsPublisher(metricsDriver, this.metricsErrorHandler, metricsContext);

        return MetricsImpl.publisher;
    }

    public getMetricsPublisher(methodName: string): KatalMetricsPublisher {
        return MetricsImpl.getPublisher().newChildActionPublisherForMethod(methodName);
    }

    public getInitializationMetricsPublisher(): KatalMetricsPublisher {
        return MetricsImpl.getPublisher().newChildActionPublisherForInitialization();
    }
}

export const Metrics = new MetricsImpl();

export class ClickMetrics implements MetricEmitter {
    private publisher: KatalMetricsPublisher;

    constructor(groupName: string) {
        this.publisher = Metrics.getMetricsPublisher(groupName);
    }

    addCount(name: string, value: number): void {
        this.publisher.publishCounter(name, value);
    }
}

export class CallMetrics {
    private publisher: KatalMetricsPublisher;

    constructor(groupName: string) {
        this.publisher = Metrics.getMetricsPublisher(groupName);
    }

    public publishCallFatalMetrics(): void {
        this.publisher.publishCounter("call_fatal", 1);
        this.publisher.publishCounter("call_success", 0);
    }

    public publishCallErrorMetrics(): void {
        this.publisher.publishCounter("call_error", 1);
        this.publisher.publishCounter("call_success", 0);
    }

    public publishCallSuccessMetrics(): void {
        this.publisher.publishCounter("call_fatal", 0);
        this.publisher.publishCounter("call_success", 1);
    }

    public publishCallLatencyMetrics(latency: number): void {
        this.publisher.publishTimer("call_latency", latency);
    }
}
